import type { ReactElement, KeyboardEvent } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import Image from 'next/legacy/image';
import { useTranslation } from 'next-i18next';
import { colors, media } from '../core/styles';
import { Typography } from '../core/typography/Typography';
import { Button } from '../core/button/Button';
import { InputField } from '../core/form';
import type { Global404 as Global404Schema } from '../../../amplienceTypes/schemas/imported/global-404-schema';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import { useSearchContext } from '../../../utilities/context/dynamic/SearchContext';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';
import { parseInternalSearchDataLayer } from '../../../utilities/vendors';

const S = {
  Section: styled.section`
    margin: 48px;
    text-align: center;

    @media ${media.greaterThan('sm')} {
      max-width: 900px;
      margin: 48px auto;
      text-align: left;
    }
  `,

  Image: styled.div`
    height: 200px;
    width: auto;
    position: relative;
    margin-bottom: 20px;

    @media ${media.greaterThan('sm')} {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
      margin-right: 20px;
      width: calc(35% - 20px);
      height: 432px;
    }
  `,

  ContentWrapper: styled.div`
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: middle;

    @media ${media.greaterThan('sm')} {
      margin-bottom: 0;
      width: 65%;
    }
  `,

  Title: styled(Typography)`
    line-height: 0.8;
    margin-bottom: 20px;
    text-transform: none;
    font-size: 40px;
    text-align: center;

    @media ${media.greaterThan('sm')} {
      text-align: left;
    }
  `,

  Subtitle: styled(Typography)`
    margin-bottom: 13px;
    text-align: center;

    @media ${media.greaterThan('sm')} {
      text-align: left;
    }
  `,

  FormWrapper: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    @media ${media.greaterThan('sm')} {
      flex-direction: row;
      gap: 20px;
      width: 90%;
    }
  `,

  Button: styled(Button)`
    width: 100%;
    font-size: 13px;

    @media ${media.greaterThan('sm')} {
      min-width: 200px;
      box-sizing: content-box;
      width: unset;
    }
  `,

  InputWrapper: styled.div`
    padding-top: 12px;
    visibility: hidden;

    @media ${media.greaterThan('sm')} {
      padding-top: 0;
      width: 100%;
    }

    @media ${media.greaterThan('xl')} {
      visibility: visible;
    }
  `,

  InputField: styled.div`
    input {
      height: unset;
    }
  `,

  MagnifierBtn: styled.button`
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: 10px;
    cursor: pointer;
    font-size: 13px;
    border: none;
    outline: 0;
    color: ${colors.NERO_GREY};

    &::before {
      font-family: gstar-icons;
      content: '\\F187';
      line-height: 1em;
      margin-left: 0.2em;
      margin-right: 0.2em;
      font-weight: 400;
    }
  `,
};

export const PageNotFound = ({
  title,
  subtitle,
  btnLabel,
  searchLabel,
}: Global404Schema): ReactElement => {
  const { locale } = useAppContext();
  const { hasSearchV2 } = useSearchContext();
  const { pushToDataLayer } = useDataLayerContext();

  const { t } = useTranslation('common', { keyPrefix: 'global404' });

  const [term, setTerm] = useState('');
  const router = useRouter();
  const redirectToSearch = (searchTerm: string) => {
    if (searchTerm) {
      router.push(`/search?terms=${term}`);
    }
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;

    setTerm(value);

    if (e.key === 'Enter' && value) {
      redirectToSearch(value);
      pushToDataLayer(parseInternalSearchDataLayer('enter', value));
    }
  };

  const handleMagnifierClick = () => {
    redirectToSearch(term);
    pushToDataLayer(parseInternalSearchDataLayer('click', term));
  };

  return (
    <S.Section>
      <S.Image>
        <Image
          alt=""
          src={`${process.env.CLOUDINARY_MEDIA_URL2}image/upload/f_auto/Error_Page.jpg`}
          layout="fill"
          objectFit="contain"
          priority
        />
      </S.Image>
      <S.ContentWrapper>
        <S.Title component="h1" variant="h3">
          {title || t('title')}
        </S.Title>
        <S.Subtitle variant="body">{subtitle || t('subtitle')}</S.Subtitle>
        <S.FormWrapper>
          <S.Button label={btnLabel || t('btnLabel')} href={`/${locale}`} />
          {!hasSearchV2 && (
            <S.InputWrapper>
              <S.InputField>
                <InputField
                  placeholder={searchLabel || t('searchLabel')}
                  name="search"
                  onKeyUp={handleKeyUp}
                />
              </S.InputField>
              <S.MagnifierBtn aria-label="Search" onClick={handleMagnifierClick} />
            </S.InputWrapper>
          )}
        </S.FormWrapper>
      </S.ContentWrapper>
    </S.Section>
  );
};
