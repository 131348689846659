import type { ReactElement } from 'react';
import styled from 'styled-components';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { ReturnFeeType } from '../../../../utilities/graphql/codegen';
import { colors } from '../../../shared/core/styles';

const S = {
  ReturnFeeContainer: styled.div`
    padding: 4px 0 20px;
    display: flex;
    align-items: center;
    gap: 5px;
  `,

  Label: styled.p<{ $color: string }>`
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    position: relative;
    padding-left: 25px;
    color: ${({ $color }) => $color};

    &::before {
      content: '\\F185';
      font-family: gstar-icons;
      font-style: normal;
      font-size: 12px;
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 4px;
    }
  `,
};

export const ProductReturnFees = (): ReactElement => {
  const {
    product: { returnFee },
  } = usePDPContext();
  const {
    configuration: {
      enableReturnFeeMDDC,
      enablePdpFreeReturnsMessage,
      enablePdpChargedReturnsMessage,
    },
  } = useStaticContext();

  const returnType = returnFee?.returnType;

  if (
    !returnFee ||
    !enableReturnFeeMDDC ||
    (returnType === ReturnFeeType.FreeReturns && !enablePdpFreeReturnsMessage) ||
    (returnType === ReturnFeeType.ChargedReturns && !enablePdpChargedReturnsMessage) ||
    (returnType === ReturnFeeType.CategoryChargedReturns && !enablePdpChargedReturnsMessage)
  ) {
    return <></>;
  }

  const labelColor =
    returnFee.color ||
    (returnType === ReturnFeeType.FreeReturns
      ? colors.FREE_RETURN_FEE_GREEN
      : colors.CHARGED_RETURN_FEE_RED);

  return (
    <S.ReturnFeeContainer>
      <S.Label
        data-testid={`product-return-fee-${returnType?.replace(/Returns/, '')}`}
        dangerouslySetInnerHTML={{ __html: returnFee.message ?? '' }}
        $color={labelColor}
      />
    </S.ReturnFeeContainer>
  );
};
