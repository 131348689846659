import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { imageBlurData } from '../../../../utilities/constants/base64Images';
import { media } from '../../../shared/core/styles';

export interface ProductZoomImageProps {
  url?: string;
  altText?: string;
  isShow?: boolean;
}

const S = {
  Img: styled.img`
    padding: 0;
    display: block;
    opacity: 0;
    transition: opacity 250ms ease-in-out 0s;
    max-width: 300%;
    aspect-ratio: 1 / 1;
    height: 100%;
    width: auto;
    user-select: none;

    &.isLoaded {
      opacity: 1;
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }

    @media only screen and ${media.greaterThan('xs')} and (orientation: landscape) {
      height: auto;
      width: 100%;
    }

    @media ${media.greaterThan('sm')} {
      position: static;
      height: auto;
      width: auto;
    }
  `,
};

export const ProductZoomImage = ({ url, altText, isShow }: ProductZoomImageProps): ReactElement => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    setIsLoaded(false);
  }, [url]);

  const loadingStateHandler = () => {
    if (!isLoaded && isShow) {
      setIsLoaded(true);
    }
  };

  return (
    <>
      <S.Img
        onLoad={loadingStateHandler}
        onError={loadingStateHandler}
        className={isLoaded ? 'isLoaded' : ''}
        data-testid="image-zoom-image"
        src={isShow ? url?.replace('{{dimensions}}', 'h_2000') : imageBlurData}
        alt={altText || ''}
        width={2000}
        height={2000}
      />
    </>
  );
};
