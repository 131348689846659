import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { REGEX_BCI } from '../../../utilities/constants/regex';
import { colors, media } from '../../shared/core/styles';
import type { Maybe, Fiber } from '../../../utilities/graphql/codegen';
import { useLayoutDesignContext } from '../../../utilities/context/dynamic/LayoutDesignContext';

interface FiberRankingDescriptionProps {
  description?: string;
  fibersData: Maybe<Fiber>[];
}

const S = {
  Wrapper: styled.div<{ $hasProductAccordions?: boolean }>`
    grid-area: description;
    align-self: flex-start;
    position: relative;
    padding: ${({ $hasProductAccordions }) => ($hasProductAccordions ? '0' : '0 16px 32px')};

    @media ${media.greaterThan('lg')} {
      padding: ${({ $hasProductAccordions }) => ($hasProductAccordions ? '0' : '0 64px 0 0')};
    }

    line-height: 1.5em;

    a {
      text-decoration: underline;
      color: currentcolor;
    }
  `,

  BaseDescription: styled.p<{ $hasProductAccordions?: boolean }>`
    font-size: 13px;
    color: ${({ $hasProductAccordions }) =>
      $hasProductAccordions ? colors.NERO_BLACK : colors.ACCESSIBILITY_GREY};
    line-height: 20px;

    a {
      &:hover,
      &:focus {
        color: ${colors.NERO_GREY};
      }
    }
  `,

  DescriptionContainer: styled.ul<{ $hasProductAccordions?: boolean }>`
    margin: ${({ $hasProductAccordions }) => ($hasProductAccordions ? '24px 0 0 0' : '24px 0')};
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding-left: 0;
    list-style-type: none;
  `,

  Description: styled.li<{ $hasProductAccordions?: boolean }>`
    margin: 0 0 1em;
    padding-left: 12px;
    border-left: 5px solid ${colors.FIBER_RANKING_COLOR_BCI};
    padding-right: 24px;
    width: 100%;
    font-size: 13px;
    line-height: 1.5em;

    ${({ $hasProductAccordions }) =>
      !$hasProductAccordions &&
      css`
        @media ${media.greaterThan('xxl')} {
          width: 50%;

          &:only-child {
            width: 80%;
          }
        }
      `}

    &.A {
      border-color: ${colors.FIBER_RANKING_COLOR_A};
    }

    &.B {
      border-color: ${colors.FIBER_RANKING_COLOR_B};
    }

    &.C {
      border-color: ${colors.FIBER_RANKING_COLOR_C};
    }

    &.D {
      border-color: ${colors.FIBER_RANKING_COLOR_D};
    }

    &.E {
      border-color: ${colors.FIBER_RANKING_COLOR_E};
    }

    &.BCI {
      border-color: ${colors.FIBER_RANKING_COLOR_BCI};
    }

    a {
      text-decoration: underline;

      &:hover,
      &:focus {
        color: ${colors.NERO_GREY};
      }
    }
  `,

  DescriptionTerm: styled.span`
    display: inline;
    font-weight: 700;
    color: ${colors.NERO_GREY};

    &::after {
      content: ': ';
    }
  `,

  DescriptionDetail: styled.div<{ $hasProductAccordions?: boolean }>`
    display: inline;
    color: ${({ $hasProductAccordions }) =>
      $hasProductAccordions ? colors.NERO_BLACK : colors.ACCESSIBILITY_GREY};
    box-sizing: border-box;
  `,
};

export const FiberRankingDescription = ({
  description,
  fibersData,
}: FiberRankingDescriptionProps): ReactElement => {
  const { hasProductAccordions } = useLayoutDesignContext();

  return (
    <S.Wrapper
      data-testid="product-fiber-ranking-description"
      $hasProductAccordions={hasProductAccordions}
      id="fiber-ranking-description"
    >
      <S.BaseDescription
        dangerouslySetInnerHTML={{ __html: description ?? '' }}
        $hasProductAccordions={hasProductAccordions}
      />
      <S.DescriptionContainer $hasProductAccordions={hasProductAccordions}>
        {fibersData &&
          fibersData.map((desc, index) => (
            <S.Description
              className={REGEX_BCI.test(desc?.code ?? '') ? 'BCI' : desc?.ranking ?? ''}
              key={index}
              $hasProductAccordions={hasProductAccordions}
            >
              {desc?.descriptionShort && desc?.descriptionLong && (
                <>
                  <S.DescriptionTerm>{desc?.descriptionShort}</S.DescriptionTerm>
                  <S.DescriptionDetail $hasProductAccordions={hasProductAccordions}>
                    {desc?.descriptionLong}
                  </S.DescriptionDetail>
                </>
              )}
            </S.Description>
          ))}
      </S.DescriptionContainer>
    </S.Wrapper>
  );
};
