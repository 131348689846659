import { useState } from 'react';
import styled from 'styled-components';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useMediaQueryContext } from '../../../../utilities/context/dynamic/MediaQueryContext';
import { useShippingEstimatesQuery } from '../../../../utilities/graphql/codegen';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { useSizeContext } from '../../../../utilities/context/dynamic/SizeContext';
import { Typography } from '../../../shared/core/typography/Typography';
import { colors } from '../../../shared/core/styles';
import { Dialog } from '../../../shared/core/dialog/Dialog';
import { ProductShippingEstimatesDisclaimer } from './ProductShippingEstimatesDisclaimer';

const S = {
  Wrapper: styled.div`
    margin-bottom: 8px;
  `,
  Description: styled(Typography)`
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: inline-block;
  `,
  DisclaimerContainer: styled.span`
    position: relative;
    display: inline-block;
  `,
  DisclaimerIcon: styled.span`
    color: ${colors.COLOR_GREY_60};
    font-size: 12px;
    line-height: 16px;
    margin-left: 4px;

    &::before {
      font-family: gstar-icons;
      font-style: normal;
      content: '\\F155';
      margin: 0 0.2em;
    }

    &:hover,
    &:focus {
      color: ${colors.BLACK};
    }
  `,
  InfoTip: styled.div`
    width: 400px;
    margin: 0;
    font-style: normal;
    box-shadow: 10px 10px 140px -20px rgba(0 0 0 / 25%);
    position: absolute;
    left: -168px;
    bottom: 40px;
    transform: translateX(-50%);

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid white;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transform: translateX(160px);
    }
  `,
  Dialog: styled(Dialog)`
    height: inherit;
    top: 0;
    left: 0;
    transform: none;
  `,
};

export const ProductShippingEstimates = () => {
  const { locale } = useAppContext();
  const {
    configuration: { enablePdpShippingEstimate, enablePdpShippingEstimateDisclaimer },
  } = useStaticContext();
  const {
    product: { code, oneSize, sizeInformation },
  } = usePDPContext();
  const {
    gridValues: { gridValue1, gridValue2 },
  } = useSizeContext();
  const { isMobile } = useMediaQueryContext();

  const [disclaimerOpened, setDisclaimerOpened] = useState(false);
  const openDisclaimer = () => setDisclaimerOpened(true);
  const closeDisclaimer = () => setDisclaimerOpened(false);

  const firstGridValue =
    oneSize && sizeInformation?.sizes ? sizeInformation.sizes[0]?.gridValue1 : gridValue1;
  const gridValue1Req = firstGridValue ? { gridValue1: firstGridValue } : {};
  const gridValue2Req = gridValue2 ? { gridValue2 } : {};

  const { error, data, previousData } = useShippingEstimatesQuery({
    fetchPolicy: 'network-only',
    variables: {
      locale,
      code,
      ...gridValue1Req,
      ...gridValue2Req,
    },
    skip: !enablePdpShippingEstimate || gridValue1 === undefined || gridValue2 === undefined,
    ssr: false,
  });

  // use 'stale' previous data while new data is being loaded to prevent layout shifts and content flash
  const { description } = data?.shippingEstimates ?? previousData?.shippingEstimates ?? {};

  if (!enablePdpShippingEstimate || !description || error) {
    return <></>;
  }

  return (
    <S.Wrapper>
      <S.Description color={colors.LIGHT_GREEN} testId="product-shipping-estimates">
        {description}
      </S.Description>

      {enablePdpShippingEstimateDisclaimer && (
        <S.DisclaimerContainer>
          <S.DisclaimerIcon
            onClick={openDisclaimer}
            data-testid="shipping-estimates-disclaimer-icon"
          />
          {disclaimerOpened &&
            (isMobile ? (
              <S.Dialog
                ariaLabel="product-shipping-estimate-info"
                onClose={closeDisclaimer}
                testId="shipping-estimates-disclaimer-overlay"
              >
                <ProductShippingEstimatesDisclaimer onClick={closeDisclaimer} />
              </S.Dialog>
            ) : (
              <S.InfoTip data-testid="shipping-estimates-disclaimer-overlay">
                <ProductShippingEstimatesDisclaimer onClick={closeDisclaimer} />
              </S.InfoTip>
            ))}
        </S.DisclaimerContainer>
      )}
    </S.Wrapper>
  );
};
