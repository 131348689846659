import type { ReactElement } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import type { ProductFiberRanking as FiberRankingSchema } from '../../../amplienceTypes/schemas/imported/product-fiber-ranking-schema';
import { REGEX_BCI } from '../../../utilities/constants/regex';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { colors, media } from '../../shared/core/styles';
import { useLayoutDesignContext } from '../../../utilities/context/dynamic/LayoutDesignContext';
import { FiberRankingData } from './FiberRankingData';
import { FiberRankingDescription } from './FiberRankingDescription';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';

const S = {
  Wrapper: styled.div`
    &,
    * {
      box-sizing: border-box;
    }

    display: grid;
    gap: 0;
    grid-template-areas: 'title' 'data' 'description';
    justify-items: center;
    max-width: 500px;
    margin: 0 auto;

    @media ${media.between('lg', 'xxl')} {
      grid-template:
        'data title' min-content
        'data description' min-content
        / 50% 50%;
      max-width: none;
      padding: 3% 0;
    }

    @media ${media.greaterThan('xxl')} {
      max-width: none;
      grid-template-areas: 'data title' 'data description';
      grid-template-columns: ${`${(5 / 12) * 100}%`} ${`${(7 / 12) * 100}%`};
      padding: 3% 0;
    }
  `,

  DataWrapper: styled.div`
    grid-area: data;
    align-self: center;
    padding: 0 16px;
    width: 100%;
    max-width: 640px;
    margin-bottom: 32px;

    @media ${media.greaterThan('lg')} {
      padding: 0 64px;
      margin: 0;
    }
  `,

  Title: styled.h2`
    grid-area: title;
    align-self: flex-end;
    font-size: 30px;
    color: ${colors.NERO_GREY};
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1em;
    text-align: center;
    margin: 32px 0 12px;
    padding: 0 16px;
    font-family: 'Gotham HCo', Arial, Helvetica, sans-serif;

    @media ${media.greaterThan('lg')} {
      padding: 0 64px 0 0;
      text-align: left;
      width: 100%;
    }
  `,
};

export const FiberRanking = (schemaData: FiberRankingSchema): ReactElement => {
  const { title, description } = schemaData;
  const { t } = useTranslation('pdp', { keyPrefix: 'productFiberRanking' });
  const {
    configuration: { fiberRanking: fiberRankingEnabled },
  } = useStaticContext();
  const { pushToDataLayer } = useDataLayerContext();
  const {
    product: { code, fiberRanking },
  } = usePDPContext();
  const { hasProductAccordions } = useLayoutDesignContext();

  useEffect(() => {
    if (fiberRankingEnabled && fiberRanking?.fiberRanking?.productFiberRankingData) {
      pushToDataLayer({
        events: {
          category: 'fiber ranking',
          action: 'details created',
          label: undefined,
        },
        product: {
          materialNumber: code,
        },
        event: 'fiberranking-details-created',
      });
    }
  }, [
    code,
    fiberRanking?.fiberRanking?.productFiberRankingData,
    fiberRankingEnabled,
    pushToDataLayer,
  ]);

  let hasBCI = false;

  fiberRanking?.fiberRanking?.productFiberRankingData?.fibers?.forEach(fiber => {
    if (REGEX_BCI.test(fiber?.code ?? '')) {
      hasBCI = true;
    }
  });

  const fibersData = [...(fiberRanking?.fiberRanking?.productFiberRankingData?.fibers ?? [])].sort(
    (a, b) => (b?.score ?? 0) - (a?.score ?? 0) || (b?.percentage ?? 0) - (a?.percentage ?? 0)
  );

  if (hasBCI) {
    fibersData?.sort((a, b) => {
      if (REGEX_BCI.test(a?.code ?? '')) {
        return 1;
      }

      if (REGEX_BCI.test(b?.code ?? '')) {
        return -1;
      }

      return 0;
    });
  }

  if (!fiberRankingEnabled || !fiberRanking?.fiberRanking?.productFiberRankingData) {
    return <></>;
  }

  if (hasProductAccordions) {
    return (
      <div data-testid="fiber-ranking" id="responsible-materials-ranking">
        <S.Title data-testid="product-fiber-ranking-title" data-accordion-title>
          {title}
        </S.Title>
        <FiberRankingData fibersData={fibersData} schemaData={schemaData} />
        <FiberRankingDescription fibersData={fibersData} description={description} />
      </div>
    );
  }

  return (
    <S.Wrapper
      data-testid="fiber-ranking"
      data-qubit-id="fiber-ranking"
      id="responsible-materials-ranking"
    >
      <S.DataWrapper>
        <FiberRankingData fibersData={fibersData} schemaData={schemaData} />
      </S.DataWrapper>
      <S.Title data-testid="product-fiber-ranking-title" id="responsible-materials-ranking-header">
        {title || t('title')}
      </S.Title>
      <FiberRankingDescription
        fibersData={fibersData}
        description={description || t('description')}
      />
    </S.Wrapper>
  );
};
