export const fiberChartTemplate = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132 132" tabindex="0" role="presentation" aria-labelledby="responsible-materials-ranking-header" aria-describedby="fiber-ranking-description">
    <g class="circles" style="mask: url(#fiberRanking-circleMask)">
      <rect x="0" y="0" width="128" height="128" style="stroke:none; fill: #fff; opacity:1;"/>
      <circle class="circle-BCI" transform="rotate(-180, 66, 66)" cx="66" cy="66" r="48" fill="none" opacity="0.4" stroke="url(#fiberRanking-pattern-BCI)" stroke-miterlimit="0" stroke-width="20" />
      <circle class="circle-E" transform="rotate(-180, 66, 66)" cx="66" cy="66" r="48" fill="none" stroke="#db6332" stroke-miterlimit="0" stroke-width="20" />
      <circle class="circle-D" transform="rotate(-180, 66, 66)" cx="66" cy="66" r="48" fill="none" stroke="#e7b635" stroke-miterlimit="0" stroke-width="20" />
      <circle class="circle-C" transform="rotate(-180, 66, 66)" cx="66" cy="66" r="48" fill="none" stroke="#d2e24d" stroke-miterlimit="0" stroke-width="20" />
      <circle class="circle-B" transform="rotate(-180, 66, 66)" cx="66" cy="66" r="48" fill="none" stroke="#81c453" stroke-miterlimit="0" stroke-width="20" />
      <circle class="circle-A" transform="rotate(-180, 66, 66)" cx="66" cy="66" r="48" fill="none" stroke="#00a943" stroke-miterlimit="0" stroke-width="20" />
      <line class="line" x1="66" y1="66" x2="0" y2="66" stroke="#ffffff" opacity="0.8" stroke-width="0.8" />
    </g>
    <g fill="#303030">
      <g class="leave" transform="translate(3, 2)">
        <path d="M52.456 71.122a3.79 3.79 0 01-.327-.569c-1.849-3.996 1.487-11.08 7.514-14.506 4.411-2.507 10.012-2.398 16.758.269l.538.213-.082.564c-1.027 7.086-3.731 11.917-8.142 14.425-5.241 2.979-12.103 3.025-15.236.653-.006-.001-.817-.654-1.023-1.049zm15.521-.863c3.855-2.191 6.307-6.439 7.331-12.804-6.111-2.31-11.072-2.342-14.927-.15-5.425 3.084-8.405 9.411-6.909 12.643l.005.011c.001-.011.364.592.97 1.127 2.565 1.885 8.765 1.882 13.53-.827z"/>
        <path d="M64.923 62.61a.684.684 0 01.848 1.067l-.109.086-15.586 10.003a.684.684 0 01-.848-1.067l.109-.086L64.923 62.61z"/>
      </g>
    </g>
  </svg>
`;
