import type { ReactElement } from 'react';
import styled from 'styled-components';
import Image from 'next/legacy/image';
import { useTranslation } from 'next-i18next';
import { media } from '../core/styles';
import { Typography } from '../core/typography/Typography';
import { Button } from '../core/button/Button';
import type { Global500 as Global500Schema } from '../../../amplienceTypes/schemas/imported/global-500-schema';
import { useAppContext } from '../../../utilities/context/static/AppContext';

const S = {
  Section: styled.section`
    margin: 48px;
    text-align: center;

    @media ${media.greaterThan('sm')} {
      max-width: 900px;
      margin: 48px auto;
      text-align: left;
    }
  `,

  Image: styled.div`
    height: 200px;
    width: auto;
    position: relative;
    margin-bottom: 20px;

    @media ${media.greaterThan('sm')} {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
      margin-right: 20px;
      width: calc(35% - 20px);
      height: 432px;
    }
  `,

  ContentWrapper: styled.div`
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: middle;

    @media ${media.greaterThan('sm')} {
      margin-bottom: 0;
      width: 65%;
    }
  `,

  Title: styled(Typography)`
    line-height: 0.8;
    margin-bottom: 20px;
    text-transform: none;
    font-size: 40px;
    text-align: center;

    @media ${media.greaterThan('sm')} {
      text-align: left;
    }
  `,

  Subtitle: styled(Typography)`
    margin-bottom: 13px;
    text-align: center;

    @media ${media.greaterThan('sm')} {
      text-align: left;
    }
  `,

  ButtonPanel: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    @media ${media.greaterThan('sm')} {
      flex-direction: row;
      gap: 20px;
      width: 90%;
    }
  `,

  Button: styled(Button)`
    width: 100%;
    font-size: 13px;

    @media ${media.greaterThan('sm')} {
      min-width: 200px;
      box-sizing: content-box;
      width: unset;
    }
  `,
};

export const ServerError = ({
  title,
  subtitle,
  refreshPage,
  goToHome,
}: Global500Schema): ReactElement => {
  const { locale } = useAppContext();

  const { t } = useTranslation('common', { keyPrefix: 'global500' });

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <S.Section>
      <S.Image>
        <Image
          src={`${process.env.CLOUDINARY_MEDIA_URL2}image/upload/f_auto/Error_Page_503.jpg`}
          layout="fill"
          objectFit="contain"
        />
      </S.Image>
      <S.ContentWrapper>
        <S.Title component="h1" variant="h3">
          {title || t('title')}
        </S.Title>
        <S.Subtitle variant="body">{subtitle || t('subtitle')}</S.Subtitle>
        <S.ButtonPanel>
          <S.Button label={refreshPage || t('refreshPage')} onClick={reloadPage} />
          <S.Button label={goToHome || t('goToHome')} href={`/${locale}`} />
        </S.ButtonPanel>
      </S.ContentWrapper>
    </S.Section>
  );
};
