import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const SizeGuideIcon = ({ color = colors.BLACK }: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 26 32"
    overflow="inherit"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill={color}
        d="M11.145 0.000366211H1.14546C0.593173 0.000366211 0.145508 0.448032 0.145508 1.00032V30.9989C0.145508 31.5511 0.593173 31.9988 1.14546 31.9988H11.145C11.6973 31.9988 12.1449 31.5511 12.1449 30.9989V1.00032C12.1449 0.448032 11.6972 0.000366211 11.145 0.000366211ZM10.145 29.9989H2.14541V26.999H7.14517V24.9991H2.14541V21.9993H5.14526V19.9994H2.14541V16.9995H7.14517V14.9996H2.14541V11.9998H5.14526V9.99988H2.14541V7.00002H7.14517V5.00012H2.14541V2.00027H10.145V29.9989Z"
      />
      <path
        fill={color}
        d="M24.4441 25.2996L21.1442 28.5894V3.41065L24.4341 6.70048L25.844 5.29055L20.8443 0.290798C20.4542 -0.0969328 19.8244 -0.0969328 19.4343 0.290798L14.4346 5.29055L15.8445 6.70048L19.1443 3.41065V28.5894L15.8545 25.2996L14.4446 26.7095L19.4443 31.7093C19.8344 32.097 20.4642 32.097 20.8543 31.7093L25.854 26.7095L24.4441 25.2996Z"
      />
    </g>
  </Svg>
);

export default SizeGuideIcon;
