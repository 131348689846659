import type { ReactElement } from 'react';
import { Fragment } from 'react';
import type { PDPMarketingComponents } from '../../../amplienceTypes/schemas/imported/pdp-marketing-components-schema';
import { unmanagedComponents } from '../../../utilities/amplience';

export const MarketingComponents = ({ components }: PDPMarketingComponents): ReactElement => {
  if (components?.content) {
    return (
      <div>
        {components.content.map((componentProps, index) => {
          // eslint-disable-next-line no-underscore-dangle
          const Component = unmanagedComponents[componentProps._meta.schema];

          return Component ? (
            <Component key={index} {...componentProps} />
          ) : (
            <Fragment key={index} />
          );
        })}
      </div>
    );
  }

  return <></>;
};
