import type { ReactElement } from 'react';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import type { Maybe, Fiber } from '../../../utilities/graphql/codegen';
import { REGEX_BCI } from '../../../utilities/constants/regex';
import { colors, media } from '../../shared/core/styles';
import { FiberRankingLegend } from './FiberRankingLegend';
import type { ProductFiberRanking as FiberRankingSchema } from '../../../amplienceTypes/schemas/imported/product-fiber-ranking-schema';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { useLayoutDesignContext } from '../../../utilities/context/dynamic/LayoutDesignContext';
import { fiberChartTemplate } from '../../shared/core/icons/plain/fiberChartTemplate';
import { fiberSvgDefs } from '../../shared/core/icons/plain/fiberSvgDefs';

const { PI } = Math;

const S = {
  Wrapper: styled.div<{ $hasProductAccordions?: boolean }>`
    margin-bottom: ${({ $hasProductAccordions }) => ($hasProductAccordions ? '20px' : '0')};

    .fiberRanking-badge {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      height: auto;

      &[data-ranking='A'] {
        .ranking {
          .letter-A {
            display: block;
          }
        }
      }

      &[data-ranking='B'] {
        .ranking {
          .letter-B {
            display: block;
          }
        }
      }

      &[data-ranking='C'] {
        .ranking {
          .letter-C {
            display: block;
          }
        }

        .leave,
        .circle-text {
          display: none;
        }
      }

      &[data-ranking='D'] {
        .ranking {
          .letter-D {
            display: block;
          }
        }

        .leave,
        .circle-text {
          display: none;
        }
      }

      &[data-ranking='E'] {
        .ranking {
          .letter-E {
            display: block;
          }
        }

        .leave,
        .circle-text {
          display: none;
        }
      }

      svg {
        display: block;

        .ranking {
          path {
            display: none;
          }
        }

        width: 50%;
        margin: 0 auto;
      }

      margin: 0 auto 32px;
    }

    .fiberRanking-label {
      position: absolute;
      font-size: 10px;
      transform: translateY(-25%);
      width: 25%;

      @media ${media.lessThan('xl')} {
        width: 30%;
      }

      &--left {
        text-align: right;
        right: 50%;
        margin-right: 25.5%;
      }

      &--right {
        text-align: left;
        left: 50%;
        margin-left: 25.5%;
      }

      &Material {
        font-weight: 700;
        line-height: 1.1;
        color: ${colors.NERO_GREY};
      }
    }

    .fiberRanking-material {
      position: relative;
      background-color: ${colors.WHITE};
      margin-bottom: 4px;
      border: 1px solid rgba(${colors.NERO_GREY}, 0.1);
      text-transform: uppercase;

      &Percentage {
        position: absolute;
        width: 0;
        left: 0;
        height: 100%;
        background-color: ${colors.ACCESSIBILITY_GREY};
        z-index: 5;
      }

      &Name {
        display: block;
        position: relative;
        z-index: 10;
        font-size: 11px;
        padding: 4px 8px;
        font-weight: 700;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        strong {
          display: inline-block;
          min-width: 2em;
          text-align: right;
          font-family: 'Gotham HCo', Arial, Helvetica, sans-serif;
          font-weight: 700;
        }
      }

      &--A {
        .fiberRanking-materialPercentage {
          background-color: ${colors.FIBER_RANKING_COLOR_A};
        }
      }

      &--B {
        .fiberRanking-materialPercentage {
          background-color: ${colors.FIBER_RANKING_COLOR_B};
        }
      }

      &--C {
        .fiberRanking-materialPercentage {
          background-color: ${colors.FIBER_RANKING_COLOR_C};
        }
      }

      &--D {
        .fiberRanking-materialPercentage {
          background-color: ${colors.FIBER_RANKING_COLOR_D};
        }
      }

      &--E {
        .fiberRanking-materialPercentage {
          background-color: ${colors.FIBER_RANKING_COLOR_E};
        }
      }

      &Notes {
        margin-top: 8px;
        font-size: 10px;
        display: none;

        @media ${media.lessThan('xl')} {
          display: block;
        }
      }

      &Note {
        position: relative;
        padding-left: 10px;
        padding-right: 20px;

        .productTile & {
          display: none;
        }

        .symbol {
          position: absolute;
          left: 0;
          top: 0;
          padding: 2px;
        }
      }
    }
  `,
};

interface FiberRankingDataProps {
  schemaData: FiberRankingSchema;
  fibersData: Maybe<Fiber>[];
}

export const FiberRankingData = ({
  schemaData,
  fibersData,
}: FiberRankingDataProps): ReactElement => {
  const {
    product: { fiberRanking },
  } = usePDPContext();
  const { hasProductAccordions } = useLayoutDesignContext();

  const badgeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const createChart = () => {
      if (!badgeRef.current || badgeRef.current.childNodes.length > 0) {
        return;
      }

      document.body.insertAdjacentHTML('beforeend', fiberSvgDefs);
      badgeRef.current.classList.add('fiberRanking-badge');
      badgeRef.current.dataset.ranking =
        fiberRanking?.fiberRanking?.productFiberRankingData?.ranking ?? '';
      badgeRef.current.insertAdjacentHTML('afterbegin', fiberChartTemplate);

      const svgElm = badgeRef.current.querySelector('svg');

      svgElm?.querySelectorAll('.circles circle').forEach(elm => {
        const r = +(elm.getAttribute('r') ?? '0');
        const pathLength = 2 * r * PI;

        elm.setAttribute('stroke-dasharray', `${pathLength}`);
        elm.setAttribute('stroke-dashoffset', `${pathLength}`);
      });

      const lineElm = svgElm?.querySelector('.circles .line');

      lineElm?.remove();

      let percentage = 0;

      const svgWidth = svgElm?.viewBox.baseVal.width || 132;
      const svgHeight = svgElm?.viewBox.baseVal.height || 132;

      fibersData.forEach(fiber => {
        const code = fiber?.code ?? '';
        const ranking = REGEX_BCI.test(code) ? 'BCI' : fiber?.ranking ?? '';
        const name = fiber?.descriptionShort ?? '';

        // add svg percentage
        percentage += fiber?.percentage ?? 0;

        const circleElm = svgElm?.querySelector(`.circle-${ranking}`);

        if (circleElm) {
          const r = Number(circleElm.getAttribute('r'));
          const pathLength = 2 * r * PI;
          const strokeDashoffset = pathLength - pathLength * (percentage / 100);

          circleElm.setAttribute('stroke-dashoffset', `${strokeDashoffset}`);

          const strokeWidth = Number(circleElm.getAttribute('stroke-width')) / 2 - 1;
          const cx = Number(circleElm.getAttribute('cx'));
          const cy = Number(circleElm.getAttribute('cy'));

          const rotationOffset = PI;

          // create section lines
          const newLineElm = lineElm?.cloneNode() as Element;
          let x2 = cx + (r + strokeWidth) * Math.cos(2 * PI * (percentage / 100) - rotationOffset);
          let y2 = cy + (r + strokeWidth) * Math.sin(2 * PI * (percentage / 100) - rotationOffset);

          newLineElm?.setAttribute('x2', `${x2}`);
          newLineElm.setAttribute('y2', `${y2}`);
          (circleElm.parentNode as Element).insertAdjacentElement('beforeend', newLineElm);

          // create label lines
          const x1 =
            cx +
            (r + strokeWidth) *
              Math.cos(
                2 * PI * ((percentage - (fiber?.percentage ?? 0) / 2) / 100) - rotationOffset
              );
          const y1 =
            cy +
            (r + strokeWidth) *
              Math.sin(
                2 * PI * ((percentage - (fiber?.percentage ?? 0) / 2) / 100) - rotationOffset
              );

          x2 =
            cx +
            (r + strokeWidth + 0) *
              Math.cos(
                2 * PI * ((percentage - (fiber?.percentage ?? 0) / 2) / 100) - rotationOffset
              );
          y2 =
            cy +
            (r + strokeWidth + 0) *
              Math.sin(
                2 * PI * ((percentage - (fiber?.percentage ?? 0) / 2) / 100) - rotationOffset
              );

          const x3 =
            percentage - (fiber?.percentage ?? 0) / 2 < 25 ||
            percentage - (fiber?.percentage ?? 0) / 2 > 75
              ? 0
              : svgWidth;

          const labelPathSVG = `<path data-id="${code}" data-d="M${x1} ${y1} L${x2} ${y2} H${x3}" d="M${x1} ${y1} L${x2} ${y2} H${x3}" fill="none" stroke="#303030" opacity="0.5" stroke-width="0.5" />`;

          svgElm?.insertAdjacentHTML('beforeend', labelPathSVG);

          const labelHTML = `<div data-id="${code}" class="fiberRanking-label fiberRanking-label--${
            x3 === 0 ? 'left' : 'right'
          }" data-top="${(y2 / svgHeight) * 100}" style="top:${
            (y2 / svgHeight) * 100
          }%" tabindex="0">
          <div class="fiberRanking-labelMaterial fiberRanking-material--${ranking}">${name}</div>
          <div class="fiberRanking-labelMaterialPercentage">${fiber?.percentage ?? 0}%</div>
        </div>`;

          svgElm?.insertAdjacentHTML('beforebegin', labelHTML);
        }
      });
    };

    createChart();
  }, [fiberRanking?.fiberRanking?.productFiberRankingData?.ranking, fibersData]);

  return (
    <S.Wrapper
      data-testid="product-fiber-ranking-data"
      $hasProductAccordions={hasProductAccordions}
    >
      <div ref={badgeRef} />
      <FiberRankingLegend schemaData={schemaData} />
    </S.Wrapper>
  );
};
