import type { ReactElement } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import type { ProductNotPurchasableMessage as ProductNotPurchasableMessageSchema } from '../../../../amplienceTypes/schemas/imported/product-not-purchasable-message-schema';
import { Typography } from '../../../shared/core/typography/Typography';
import { colors } from '../../../shared/core/styles';
import { usePDPDynamicContext } from '../../../../utilities/context/dynamic/PDPDynamicContext';
import type { Size } from '../../../../utilities/graphql/codegen';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';

const S = {
  ProductNotPurchasableMessageWrapper: styled.div`
    margin: 1em 0 1.5em;
    padding: 0;
  `,
  ProductNotPurchasableMessage: styled(Typography)`
    text-align: center;
    color: ${colors.ERROR_RED};
  `,
};

interface ProductNotPurchasableMessageProps {
  selectedSize?: Size | null;
}

export const ProductNotPurchasableMessage = ({
  onlyReasonV2,
  notOnlyReasonV2,
  selectedSize,
}: ProductNotPurchasableMessageSchema & ProductNotPurchasableMessageProps): ReactElement => {
  const { t } = useTranslation('pdp', { keyPrefix: 'productNotPurchasableMessage' });
  const {
    product: { sizeInformation },
  } = usePDPContext();
  const {
    dynamicProductData: { sizeInformation: dynamicSizeInformation },
  } = usePDPDynamicContext();

  const sizes = dynamicSizeInformation?.sizes ?? sizeInformation?.sizes;

  const notOnlyReason = useMemo(
    () => sizes?.every(size => !size?.notPurchasableDueToStock),
    [sizes]
  );

  return (
    <S.ProductNotPurchasableMessageWrapper data-testid="summary-product-not-purchasable-message">
      <S.ProductNotPurchasableMessage component="div" variant="body" data-cs-capture="">
        {notOnlyReason || (selectedSize && !selectedSize?.willBeAvailable)
          ? notOnlyReasonV2 || t('notOnlyReason')
          : onlyReasonV2 || t('onlyReason')}
      </S.ProductNotPurchasableMessage>
    </S.ProductNotPurchasableMessageWrapper>
  );
};
