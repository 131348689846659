import type { ReactElement, MouseEvent, ChangeEvent } from 'react';
import { useState, useEffect } from 'react';
import type { SubmitHandler, FieldValues, UseFormRegister } from 'react-hook-form';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { colors } from '../../../shared/core/styles';
import { Button } from '../../../shared/core/button/Button';
import { Typography } from '../../../shared/core/typography/Typography';
import { useUserContext } from '../../../../utilities/context/dynamic/UserContext';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { Checkbox, InputField } from '../../../shared/core/form';
import { EntryPoint, SubscribeStatusCode, SizeType } from '../../../../utilities/graphql/codegen';
import { fetchCommonData } from '../../../../utilities/graphql/queries';
import { useExperienceContext } from '../../../../utilities/context/dynamic/ExperienceOptionsProvider';
import { getFormSchema } from '../../../../utilities/constants/formSchema';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { subscribeToGstar } from '../../../../utilities/helpers';

const S = {
  NotifyBackInStockActions: styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    strong {
      display: contents;
    }
  `,

  BackInStockNotificationForm: styled.form`
    display: flex;
    flex-direction: column;
    padding: 20px 0 12px;
  `,

  InputGroup: styled.div`
    margin-right: -1px;
    width: 100%;
    box-sizing: border-box;

    label {
      color: ${colors.ACCESSIBILITY_GREY};
      position: absolute;
      top: 16px;
      left: 12px;
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      letter-spacing: -0.32px;
      margin-bottom: 0;
      pointer-events: none;
      z-index: 1;
      transition: all 0.3s ease-out;

      @media (prefers-reduced-motion: reduce) {
        transition: none;
      }
    }

    &.labelMinimized {
      label {
        top: 8px;
        line-height: 16px;
        font-size: 12px;
      }

      input::placeholder {
        color: ${colors.ACCESSIBILITY_GREY};
        font-weight: normal;
      }
    }
  `,

  InputField: styled(InputField)`
    input {
      height: 56px;
      border: 1px solid ${colors.BALI_HAI_GREY};
      background: white;
      color: ${colors.NERO_GREY};
      font-size: 16px;
      font-weight: normal;
      padding: 20px 12px 4px;

      &[data-testid='product-subscription-email-error'] {
        border: 1px solid ${colors.CHARGED_RETURN_FEE_RED};
      }

      &::placeholder {
        color: transparent;
      }
    }
  `,

  Checkbox: styled(Checkbox)`
    padding: 8px 10px 4px 36px;
    overflow: hidden;
    opacity: 0;
    z-index: -1;
    animation: slide-down 500ms ease-out forwards;

    @keyframes slide-down {
      0% {
        opacity: 0;
        margin-top: -20%;
      }

      99% {
        z-index: -1;
      }

      100% {
        opacity: 1;
        margin-top: 0%;
        z-index: 1;
      }
    }

    a {
      text-decoration: underline;
      color: ${colors.NERO_GREY};
    }

    span {
      line-height: 1.3;
      font-size: 14px;
      padding: 0;

      &::before {
        border: 1px solid ${colors.DISABLED_GREY};
        width: 20px;
        height: 20px;
      }
    }

    input:checked + span::before {
      color: ${colors.WHITE};
      background-color: ${colors.SUCCESS_BLUE};
      font-size: 12px;
    }

    div:last-child {
      top: 5px;
    }
  `,

  SubmitBtn: styled(Button)`
    font-size: 13px;
    min-width: 56px;
    padding: 0 10px;
    height: 56px;
    border: 1px solid ${colors.BALI_HAI_GREY} !important;
    color: ${colors.BALI_HAI_GREY} !important;

    &.button--disabled {
      background-color: ${colors.WHITE};
      pointer-events: all;

      g,
      &:hover g,
      &:focus g {
        fill: ${colors.BALI_HAI_GREY};
      }
    }
  `,

  ShopSimilarItemsWrapper: styled.div`
    display: flex;
  `,

  ShopSimilarItems: styled(Button)`
    width: 100%;
    font-size: 14px;
    text-transform: none;
    padding: 0 10px;
  `,

  FormHeader: styled(Typography)`
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.28px;
    margin-bottom: 8px;
    background-color: ${colors.WHITE};

    button {
      line-height: 16px;
      text-decoration: underline;
    }
  `,

  PromptToSelectSize: styled.div`
    box-sizing: border-box;
    color: ${colors.BLUE_CHARCOAL};
    background-color: ${colors.NERO_BLACK};
    margin: 8px 0 6px;
    padding: 8px;
    font-size: 13px;
    line-height: 1.4;
  `,

  Confirmation: styled.div`
    display: flex;
    padding: 8px;
    margin: 20px 0 16px;
    background-color: ${colors.BALI_HAI_GREY};
  `,
};

interface ProductBackInStockSubscriptionType extends FieldValues {
  name: string;
  email: string;
  subscribed: boolean;
}

interface NotifyBackInStockActionsProps {
  gridValues: { [key: string]: string };
  emailLabel?: string;
  sizeType: string;
  setPromptToSelectWaist: (value: boolean) => void;
  setPromptToSelectLength: (value: boolean) => void;
  promptToSelectWaist: boolean;
  promptToSelectLength: boolean;
}

const NotifyBackInStockActions = ({
  gridValues,
  emailLabel,
  sizeType,
  setPromptToSelectWaist,
  setPromptToSelectLength,
  promptToSelectWaist,
  promptToSelectLength,
}: NotifyBackInStockActionsProps): ReactElement => {
  const { t } = useTranslation(['common', 'pdp']);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors, isSubmitting },
  } = useForm<ProductBackInStockSubscriptionType>({ mode: 'onSubmit' });

  const {
    experienceOptions: {
      tk22_1: {
        translations: {
          newsletterCheckboxText = '',
          notificationTitle = '',
          ariaSubmitButton = '',
          invalidEmailText = '',
          shopSimilarItemsButtonText = '',
          selectWaistText = '',
          selectLengthText = '',
          confirmationText = '',
        } = {},
      } = {},
    } = {},
  } = useExperienceContext();
  const [focusedInputName, setFocusedInputName] = useState('');
  const [showNewsletterSubscription, setShowNewsletterSubscription] = useState(false);
  const [showSubscribeConfirmation, setShowSubscribeConfirmation] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const { user } = useUserContext();
  const { product } = usePDPContext();
  const { formErrorLabels } = useStaticContext();

  const ProductOverlayDialogSchema = getFormSchema({
    emailRequiredLabel:
      formErrorLabels?.emailRequiredLabel ||
      t('globalFormErrorLabels.emailRequiredLabel', { ns: 'common' }),
    emailInvalidLabel:
      formErrorLabels?.emailInvalidLabel ||
      t('globalFormErrorLabels.emailInvalidLabel', { ns: 'common' }),
  });

  const sendGaEvent = (label: string) =>
    document.dispatchEvent(new CustomEvent('TK22.1-send-ga-event', { detail: { label } }));

  const subscribeProduct: SubmitHandler<ProductBackInStockSubscriptionType> = async values => {
    if (sizeType === SizeType.NumericDouble) {
      if (!gridValues.gridValue1) {
        return setPromptToSelectWaist(true);
      }

      if (!gridValues.gridValue2) {
        return setPromptToSelectLength(true);
      }
    }

    try {
      const response = await subscribeToGstar({
        isNewsLetterSubscription: values.subscribed,
        entryPoint: EntryPoint.VariantBackInStock,
        email: values.email,
        name: 'Customer',
        gridValue1: gridValues.gridValue1,
        gridValue2: gridValues.gridValue2 || undefined,
        productCode: product.baseProduct || product.code,
      });

      if (response?.subscribeStatusCode === SubscribeStatusCode.Success) {
        if (values.subscribed) {
          sendGaEvent('click_newsletteroptin_successful');
        }
      }

      const userSubscribed =
        response?.subscribeStatusCode === SubscribeStatusCode.Success ||
        response?.subscribeStatusCode === SubscribeStatusCode.Failure;

      setShowSubscribeConfirmation(userSubscribed);

      return response;
    } catch (error) {
      return { error };
    }
  };

  const subscriptionEl = (
    <Typography
      variant="body"
      component="p"
      color={colors.NERO_GREY}
      dangerouslySetInnerHTML={{
        __html: newsletterCheckboxText,
      }}
    />
  );

  const shopSimilarItemsHandler = (event: MouseEvent) => {
    (event?.currentTarget as HTMLButtonElement)?.blur();

    const { origin, pathname } = window.location;
    const splitPathname = pathname.split('/');

    splitPathname.pop();

    const newPathname = splitPathname.join('/');

    sendGaEvent('click_similar_size_button');

    window
      .open(
        `${origin}${newPathname}?q=::size:${gridValues.gridValue1}` +
          `${gridValues.gridValue2 ? `:length:${gridValues.gridValue2}` : ''}`,
        '_blank'
      )
      ?.focus();
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!regex.test(target.value)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);

      document.dispatchEvent(
        new CustomEvent('TK22.1-check-newsletter-subscription', {
          detail: { target, callback: setShowNewsletterSubscription, fetchCommonData },
        })
      );
    }
  };

  useEffect(() => {
    sendGaEvent('view_notifyme');

    document.dispatchEvent(new CustomEvent('TK22.1-init-find-in-store-button'));
  }, []);

  useEffect(() => {
    setShowSubscribeConfirmation(false);
  }, [gridValues]);

  return (
    <S.NotifyBackInStockActions data-qubit-id="notify-me-actions">
      {showSubscribeConfirmation ? (
        <S.Confirmation
          dangerouslySetInnerHTML={{
            __html: gridValues.gridValue2
              ? confirmationText.replace(
                  '{size}',
                  `${gridValues.gridValue1}/${gridValues.gridValue2}`
                )
              : confirmationText.replace('{size}', `${gridValues.gridValue1}`),
          }}
        />
      ) : (
        <S.BackInStockNotificationForm onSubmit={handleSubmit(subscribeProduct)}>
          <S.FormHeader
            component="h3"
            color={colors.BLUE_CHARCOAL}
            dangerouslySetInnerHTML={{
              __html: notificationTitle,
            }}
          />
          <S.ShopSimilarItemsWrapper>
            <S.InputGroup
              className={focusedInputName === 'email' || getValues('email') ? 'labelMinimized' : ''}
              onChange={onInputChange}
            >
              <Controller
                control={control}
                name="email"
                defaultValue={user?.email || ''}
                render={({ field: { onChange, value } }) => (
                  <S.InputField
                    label={
                      emailLabel ||
                      t('productSizeSelector.productOverlayDialog.emailLabel', { ns: 'pdp' })
                    }
                    placeholder="name@email.com"
                    name="email"
                    required={ProductOverlayDialogSchema.email.required}
                    pattern={ProductOverlayDialogSchema.email.pattern}
                    register={register as unknown as UseFormRegister<FieldValues>}
                    setValue={setValue}
                    onFocus={() => setFocusedInputName('email')}
                    onBlur={() => setFocusedInputName('')}
                    error={errors.email && invalidEmailText}
                    showError
                    testId="product-subscription-email"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </S.InputGroup>
            <S.SubmitBtn
              className={isDisabled ? 'button--disabled' : ''}
              ariaLabel={ariaSubmitButton}
              type="submit"
              ordinal="success"
              disabled={isSubmitting}
              testId="newsletter-submit"
              data-cs-capture=""
              icon="arrowRight"
              onClick={() => sendGaEvent('click_submit')}
            />
          </S.ShopSimilarItemsWrapper>
          {((promptToSelectWaist && selectWaistText) ||
            (promptToSelectLength && selectLengthText)) && (
            <S.PromptToSelectSize>
              {promptToSelectWaist ? selectWaistText : selectLengthText}
            </S.PromptToSelectSize>
          )}
          {showNewsletterSubscription && (
            <S.Checkbox
              label={subscriptionEl}
              name="subscribed"
              register={register as unknown as UseFormRegister<FieldValues>}
              testId="product-subscription-privacy-policy"
            />
          )}
        </S.BackInStockNotificationForm>
      )}
      <S.ShopSimilarItems
        label={shopSimilarItemsButtonText}
        ordinal="success"
        onClick={shopSimilarItemsHandler}
        testId="shop-similar-items"
      />
    </S.NotifyBackInStockActions>
  );
};

export default NotifyBackInStockActions;
