import type { ReactElement, MouseEventHandler } from 'react';
import styled from 'styled-components';
import { media, colors } from '../../../shared/core/styles';

interface DotNavigationProps {
  galleryZoomIn: boolean;
  currentImageIndex: number;
  clickHandlers: MouseEventHandler<HTMLElement>[];
}

const S = {
  GridCarouselNavigation: styled.div<{ $galleryZoomIn: boolean; $currentImageIndex: number }>`
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: ${({ $galleryZoomIn }) => ($galleryZoomIn ? 0 : 2)};

    button {
      display: inline-block;
      cursor: pointer;
      width: 12px;
      height: 12px;
      position: relative;
      border: none;

      &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 50%;
        margin-top: -3px;
        margin-left: -3px;
        background: ${colors.LIGHT_GREY};
        content: '';
        display: block;
        height: 6px;
        width: 6px;
      }
    }

    button:nth-child(${({ $currentImageIndex }) => $currentImageIndex + 1}) {
      &::before {
        background: ${colors.ACCESSIBILITY_GREY};
      }
    }

    @media ${media.greaterThan('sm')} {
      display: none;
    }
  `,
};

export const DotNavigation = ({
  galleryZoomIn,
  currentImageIndex,
  clickHandlers,
}: DotNavigationProps): ReactElement => (
  <S.GridCarouselNavigation
    data-testid="image-gallery-navigation"
    $galleryZoomIn={galleryZoomIn || false}
    $currentImageIndex={currentImageIndex}
  >
    {clickHandlers.map((handler, index) => (
      <button aria-label={`Image #${index + 1}`} key={index} onClick={handler} type="button" />
    ))}
  </S.GridCarouselNavigation>
);
