import type { ReactElement } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { Dialog } from '../../shared/core/dialog/Dialog';
import { breakpoints, colors } from '../../shared/core/styles';
import { FiberRankingDialog } from './FiberRankingDialog';
import { FiberRankingLegendSwatches } from './FiberRankingLegendSwatches';
import type { ProductFiberRanking as FiberRankingSchema } from '../../../amplienceTypes/schemas/imported/product-fiber-ranking-schema';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';

const S = {
  Wrapper: styled.div`
    display: block;
    color: ${colors.NERO_GREY};
    cursor: help;
    position: relative;
    max-width: 300px;
    margin: 0 auto;
  `,

  Info: styled.div`
    padding: 0;
    width: 20px;
    height: 20px;
    background-color: ${colors.NERO_GREY};
    color: ${colors.WHITE};
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    left: 100%;
    margin-left: 5px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;

    &::before {
      content: '\\F154';
      font-family: gstar-icons;
      text-align: center;
    }
  `,

  LabelsWrapper: styled.div`
    display: block;
  `,

  Labels: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 10px;
    color: ${colors.ACCESSIBILITY_GREY};

    .CenterLabel {
      text-align: center;
    }

    .LineLeft,
    .LineRight {
      flex-grow: 1;
      border: solid ${colors.FIBER_RANKING_BORDER};
      border-width: 0 0 1px 1px;
      height: 6px;
      min-width: 20px;
      margin: 2px 5px 0 15px;
    }

    .LineRight {
      border-width: 0 1px 1px 0;
      margin: 2px 15px 0 5px;
    }
  `,
};

export interface FiberRankingLegendProps {
  schemaData: FiberRankingSchema;
}

export const FiberRankingLegend = ({ schemaData }: FiberRankingLegendProps): ReactElement => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { pushToDataLayer } = useDataLayerContext();
  const {
    product: { code },
  } = usePDPContext();

  const { t } = useTranslation('pdp', { keyPrefix: 'productFiberRanking' });

  const openDialog = () => {
    setDialogOpen(true);
    pushToDataLayer({
      events: {
        category: 'fiber ranking',
        action: 'open dialog',
        label: undefined,
      },
      product: {
        materialNumber: code,
      },
      event: 'fiberranking-open-dialog',
    });
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <S.Wrapper>
      <FiberRankingLegendSwatches>
        <S.Info onClick={openDialog} data-testid="product-fiber-ranking-info" />
      </FiberRankingLegendSwatches>

      <S.LabelsWrapper onClick={openDialog}>
        <S.Labels>
          <div>{schemaData.lowestText || t('lowestText')}</div>
          <div>{schemaData.highestText || t('highestText')}</div>
        </S.Labels>
        <S.Labels>
          <div className="LineLeft" />
          <div className="CenterLabel">{schemaData.impactText || t('impactText')}</div>
          <div className="LineRight" />
        </S.Labels>
      </S.LabelsWrapper>
      {dialogOpen && (
        <Dialog
          ariaLabel="fiber rankings dialog"
          onClose={closeDialog}
          position="top"
          maxWidth={breakpoints.lg}
          overlayColor={colors.FIBER_RANKING_BG}
        >
          <FiberRankingDialog schemaData={schemaData} />
        </Dialog>
      )}
    </S.Wrapper>
  );
};
