import type { ReactElement } from 'react';
import { useId } from 'react';
import Script from 'next/script';
import type { JavaScript } from '../../../amplienceTypes/schemas/exported/javascript-code-schema';
import { URL_REGEX } from '../../../utilities/constants/regex';

export const JavascriptCode = ({ inline, src }: JavaScript): ReactElement => {
  const id = useId();
  const inlineScript = inline?.htmlEditor?.html as string;

  return (
    <>
      {inlineScript && (
        <Script id={`javascript-code-inline-script-${id}`} strategy="afterInteractive">
          {inlineScript}
        </Script>
      )}
      {URL_REGEX.test(src || '') && (
        <Script id={`javascript-code-src-script-${id}`} src={src} defer />
      )}
    </>
  );
};
