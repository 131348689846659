import type { ReactElement } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { useExperienceContext } from '../../../../utilities/context/dynamic/ExperienceOptionsProvider';
import type { ProductSizeGuide as ProductSizeGuideSchema } from '../../../../amplienceTypes/schemas/imported/product-size-guide-schema';
import { colors, media } from '../../../shared/core/styles';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { getLocation } from '../../../../utilities/helpers';
import { Dialog } from '../../../shared/core/dialog/Dialog';
import type { SizeGuideProps } from '../../sizeGuide/SizeGuide';
import { useHashChanged } from '../../../../utilities/hooks';
import { Typography } from '../../../shared/core/typography/Typography';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import type { SizeMeasurementsProps } from '../../sizeGuide/SizeMeasurements';
import { SizeGuideUnit, SupportedFitType } from '../../../../utilities/graphql/codegen';
import SizeGuideIcon from '../../../shared/core/icons/SizeGuideIcon';
import { useDataLayerContext } from '../../../../utilities/context/static/DataLayerContext';

const SizeMeasurements = dynamic<SizeGuideProps & SizeMeasurementsProps>(
  () => import('../../sizeGuide/SizeMeasurements').then(mod => mod.SizeMeasurements),
  { ssr: false }
);
const SizeGuide = dynamic<SizeGuideProps>(
  () => import('../../sizeGuide/SizeGuide').then(mod => mod.SizeGuide),
  { ssr: false }
);

const S = {
  Wrapper: styled.div`
    margin: 12px 0;
    text-align: right;

    @media ${media.greaterThan('sm')} {
      text-align: left;
    }
  `,

  ModelWears: styled.p`
    color: ${colors.BLUE_CHARCOAL};
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    text-align: left;
  `,

  SizingWrapper: styled.div<{ $isModelWearsPresent: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: ${({ $isModelWearsPresent }) => ($isModelWearsPresent ? 'row' : 'column')};
    gap: 16px;
  `,

  SizingLabel: styled(Typography)`
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: ${colors.RAVEN_GREY};
    text-transform: capitalize;
  `,

  SizeGuideLinkWrapper: styled.p`
    font-size: 12px;
    display: inline-block;
  `,

  SizeGuideLink: styled.a`
    text-decoration: none;
  `,

  SizeGuideLinkLabel: styled.span<{ $isWesterFitType: boolean }>`
    height: 20px;
    text-transform: capitalize;
    text-decoration: underline;
    margin-left: 0.2em;
    font-weight: 400;
    color: ${colors.BLUE_CHARCOAL};

    &:hover,
    &:focus {
      color: ${colors.NERO_GREY};
    }
  `,

  IconWrapper: styled.span`
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: 2px;
    vertical-align: top;
  `,

  OneSizeWrapper: styled.div`
    margin: 8px 0;
  `,

  SizeLabel: styled(Typography)`
    line-height: 18px;
    text-transform: none;
    font-size: 12px;
  `,

  OneSize: styled(Typography)`
    line-height: 18px;
    text-transform: none;
    margin-left: 7px;
  `,
};

export const SizeGuideBlock = ({
  labelProps,
}: {
  labelProps: ProductSizeGuideSchema;
}): ReactElement => {
  const { t } = useTranslation('pdp', { keyPrefix: 'productSizeGuide' });

  const {
    configuration: { enableSizeGuideProductMeasurements, sizeGuideUnit, supportedFitType },
  } = useStaticContext();
  const { experienceOptions } = useExperienceContext();

  const { sizeTextV2, oneSizeTextV2, sizeGuideLabelV2, sizeGuideLink } = labelProps;

  const { pushToContentSquare } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();
  const {
    product: { code, mannequin, shouldRequestSizeMeasurements, oneSize },
  } = usePDPContext();

  const [showSizeMeasurements, setShowSizeMeasurements] = useState(
    enableSizeGuideProductMeasurements && shouldRequestSizeMeasurements
  );

  const handleNoSizeMeasurementsData = () => {
    // To resolve race condition caused
    setTimeout(() => {
      setShowSizeMeasurements(false);
    }, 0);
  };

  const dataLayerEventData = {
    events: {
      category: 'size guide',
      action: 'open',
      label: undefined,
    },
    product: {
      materialNumber: code,
    },
    event: 'sizeguide-open',
  };

  const { hashMatched, setHashMatched } = useHashChanged(
    ['#size-guide', '#measurement-guide'],
    dataLayerEventData
  );

  const handleOpenSizeGuideModal = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setHashMatched(true);

    pushToDataLayer(dataLayerEventData);

    pushToContentSquare('trackPageview', `${getLocation()}?cs-popin-sizeguide`);
  };

  const handleCloseSizeGuideModal = () => {
    setHashMatched(false);
    pushToContentSquare('trackPageview', getLocation());
  };

  const isModelWearsPresent = Boolean(
    (sizeGuideUnit === SizeGuideUnit.Inch && mannequin?.imperialMessage) ||
      (sizeGuideUnit === SizeGuideUnit.Cm && mannequin?.metricMessage) ||
      mannequin?.additionalSizeInfo
  );

  const renderSizeGuideLink = (text: string, hLink: string) => (
    <S.SizeGuideLink href={hLink} onClick={handleOpenSizeGuideModal} data-testid="size-guide-link">
      <S.IconWrapper data-testid="size-guide-icon">
        <SizeGuideIcon />
      </S.IconWrapper>
      <S.SizeGuideLinkLabel
        data-cs-capture=""
        $isWesterFitType={supportedFitType === SupportedFitType.WesternFitType}
      >
        {text}
      </S.SizeGuideLinkLabel>
    </S.SizeGuideLink>
  );

  return oneSize ? (
    <S.OneSizeWrapper>
      <S.SizeLabel
        component="span"
        variant="h5"
        color={colors.ACCESSIBILITY_GREY}
        testId="one-size-label"
        data-cs-capture=""
      >
        {sizeTextV2 || t('sizeText')}
      </S.SizeLabel>
      <S.OneSize
        component="span"
        variant="h5"
        color={colors.NERO_GREY}
        testId="one-size-label-text"
        data-cs-capture=""
      >
        {oneSizeTextV2 || t('oneSizeText')}
      </S.OneSize>
    </S.OneSizeWrapper>
  ) : (
    <>
      {!(experienceOptions?.tk20?.experienceId && !isModelWearsPresent) && (
        <S.Wrapper>
          <S.SizingWrapper $isModelWearsPresent={isModelWearsPresent}>
            <S.SizingLabel>{sizeTextV2 || t('sizeText')}</S.SizingLabel>
            {sizeGuideLink && !(experienceOptions?.tk20?.experienceId && isModelWearsPresent) && (
              <S.SizeGuideLinkWrapper>
                {renderSizeGuideLink(sizeGuideLabelV2 || t('sizeGuideLabel'), sizeGuideLink)}
              </S.SizeGuideLinkWrapper>
            )}
          </S.SizingWrapper>
          {isModelWearsPresent && (
            <S.ModelWears data-testid="model-size-message" data-cs-capture="">
              {sizeGuideUnit === SizeGuideUnit.Inch
                ? mannequin?.imperialMessage
                : mannequin?.metricMessage}
              &nbsp;{mannequin?.additionalSizeInfo}&nbsp;&nbsp;
            </S.ModelWears>
          )}
        </S.Wrapper>
      )}
      {hashMatched && (
        <Dialog
          ariaLabel="size guide"
          onClose={handleCloseSizeGuideModal}
          position={showSizeMeasurements ? 'center' : 'top'}
          overlayColor={colors.DIALOG_DARK_GREY}
        >
          {showSizeMeasurements ? (
            <SizeMeasurements
              labelProps={labelProps}
              handleNoSizeMeasurementsData={handleNoSizeMeasurementsData}
            />
          ) : (
            <SizeGuide labelProps={labelProps} />
          )}
        </Dialog>
      )}
    </>
  );
};
