import type { ReactElement } from 'react';
import { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import Image from 'next/legacy/image';
import { useTranslation } from 'next-i18next';
import type { ProductColor as ProductColorSchema } from '../../../../amplienceTypes/schemas/imported/product-color-schema';
import { colors, media } from '../../../shared/core/styles';
import type { AnimationDirection } from '../productImageGallery/ProductImageGallery';
import { isTouchable } from '../../../../utilities/device';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import type { Color } from '../../../../utilities/graphql/codegen';
import { imageBlurData } from '../../../../utilities/constants/base64Images';
import { useGalleryContext } from '../../../../utilities/context/dynamic/GalleryContext';

export interface ProductStyleVariantsStateProps {
  color?: Color | null;
}

const styles = {
  Text: css`
    color: ${colors.BLUE_CHARCOAL};
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
    text-transform: none;

    @media ${media.greaterThan('sm')} {
      margin-bottom: 24px;
    }
  `,
  ArrowButton: css`
    cursor: pointer;
    display: none;
    position: absolute;
    height: 64px;
    top: 0;
    text-align: center;
    z-index: 2;
    background: transparent url(${imageBlurData}) 0 0 repeat scroll;

    &::before {
      display: inline-block;
      font-style: normal;
      font-weight: 400;
      width: 30px;
      line-height: 64px;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      font-size: 40px;
      color: ${colors.ACCESSIBILITY_GREY};
    }

    &:hover,
    &:focus {
      &::before {
        color: ${colors.NERO_GREY};
      }
    }
  `,
  CarouselItemCheckIcon: css`
    content: '';
    position: absolute;
    width: 6px;
    height: 18px;
    border: 3px solid ${colors.WHITE};
    border-top: none;
    border-left: none;
    top: 20px;
    left: 28px;
    transform: rotate(45deg);
    cursor: pointer;
  `,
};

const S = {
  ColorSelection: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Title: styled.p`
    ${styles.Text};
    color: ${colors.RAVEN_GREY};
    font-weight: 700;

    @media ${media.greaterThan('sm')} {
      margin-bottom: 8px;
    }
  `,
  Anchor: styled.a`
    ${styles.Text};
    text-decoration: none;

    @media ${media.greaterThan('sm')} {
      margin-bottom: 8px;
    }
  `,
  LinkLabel: styled.strong`
    color: ${colors.RAVEN_GREY};
    vertical-align: bottom;
  `,
  ColorName: styled.span`
    color: ${colors.BLUE_CHARCOAL};
    margin-left: 7px;
    text-decoration: underline;
  `,
  Carousel: styled.div`
    position: relative;
    margin-bottom: 12px;

    @media ${media.greaterThan('sm')} {
      margin: 0 12px 8px 0;
    }
  `,
  CarouselContainer: styled.div<{ $touchable: boolean }>`
    scroll-behavior: smooth;
    scrollbar-width: none;
    overflow-x: ${({ $touchable }) => ($touchable ? 'auto' : 'hidden')};
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0;
    }

    @media ${media.greaterThan('sm')} {
      width: 100%;
      min-height: 64px;
    }
  `,
  CarouselInner: styled.div<{ $itemCount: number }>`
    display: grid;
    grid-template-columns: repeat(${({ $itemCount }) => $itemCount}, 64px);
    grid-gap: 4px;
  `,
  CarouselInnerItem: styled.div<{ $isSelected: boolean }>`
    width: 64px;
    height: 64px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    border: ${({ $isSelected }) => ($isSelected ? `1px solid ${colors.BLUE_CHARCOAL}` : 'none')};
    box-sizing: border-box;
    background-color: ${({ $isSelected }) => ($isSelected ? colors.BLACK : 'transparent')};
    transition: 0.3s background-color linear;
    clip-path: circle(50%);

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
  CarouselAnchor: styled.a<{ $isSelected: boolean }>`
    display: inline-block;
    width: ${({ $isSelected }) => ($isSelected ? '62px' : '64px')};
    height: ${({ $isSelected }) => ($isSelected ? '62px' : '64px')};
    position: relative;

    img {
      opacity: ${({ $isSelected }) => ($isSelected ? '0.8' : '1')};
    }

    &::after {
      ${({ $isSelected }) => $isSelected && styles.CarouselItemCheckIcon}
    }
  `,

  ArrowLeft: styled.span<{
    $activeArrow: AnimationDirection | 'all' | undefined;
  }>`
    ${styles.ArrowButton};
    left: -30px;

    @media ${media.greaterThan('sm')} {
      display: ${({ $activeArrow }) =>
        $activeArrow === 'left' || $activeArrow === 'all' ? 'block' : 'none'};
    }

    &::before {
      content: '\\2039';
    }
  `,
  ArrowRight: styled.span<{
    $activeArrow: AnimationDirection | 'all' | undefined;
  }>`
    ${styles.ArrowButton};
    right: -30px;

    @media ${media.greaterThan('sm')} {
      display: ${({ $activeArrow }) =>
        $activeArrow === 'right' || $activeArrow === 'all' ? 'block' : 'none'};
    }

    &::before {
      content: '\\203A';
    }
  `,
};

const throttle = (() => {
  let timeout: number | undefined;

  return function throttle(callback: () => void) {
    if (timeout === undefined) {
      callback();
      timeout = window.setTimeout(() => {
        timeout = undefined;
      }, 100);
    }
  };
})();

const throttlify = (callback: (event: MouseEvent) => void) => (event: MouseEvent) => {
  throttle(() => {
    callback(event);
  });
};

export const ProductColor = ({
  colourV2,
  availableColoursV2,
  color,
}: ProductStyleVariantsStateProps & ProductColorSchema): ReactElement => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [activeArrow, setActiveArrow] = useState<AnimationDirection | 'all' | undefined>(undefined);
  const [touchable, setTouchable] = useState(false);
  const [hoverOnCarousel, setHoverOnCarousel] = useState(true);
  const { locale } = useAppContext();
  const {
    productStyleVariantIndex,
    styleVariantsSelectedIndex,
    selectColorCarouselIndex,
    styleVariants,
  } = useGalleryContext();

  const { t } = useTranslation('pdp', { keyPrefix: 'productColor' });

  useEffect(() => {
    setTouchable(isTouchable());

    const handleResize = () => setTouchable(isTouchable());

    window.addEventListener('resize', handleResize);

    if (!touchable) {
      const handleMouseMove = throttlify((e: MouseEvent) => {
        if (carouselRef.current) {
          const rect = carouselRef.current.getBoundingClientRect();

          if (
            e.clientX < rect.left ||
            e.clientX > rect.right ||
            e.clientY < rect.top ||
            e.clientY > rect.bottom
          ) {
            setHoverOnCarousel(false);
            window.removeEventListener('mousemove', handleMouseMove);
          }
        }
      });

      window.addEventListener('mousemove', handleMouseMove);

      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('resize', handleResize);
      };
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [touchable]);

  useEffect(() => {
    const firstStyleVariant = carouselRef.current?.querySelector(
      '[data-testid="style-carousel-item"]:first-child'
    );
    const lastStyleVariant = carouselRef.current?.querySelector(
      '[data-testid="style-carousel-item"]:last-child'
    );

    if (!firstStyleVariant || !lastStyleVariant) {
      return;
    }

    let isLeftArrowVisible = false;
    let isRightArrowVisible = false;

    const handleArrowVisibility = () => {
      let activeArrow: AnimationDirection | 'all' | undefined;

      if (!isLeftArrowVisible && isRightArrowVisible) {
        activeArrow = 'right';
      }

      if (isLeftArrowVisible && !isRightArrowVisible) {
        activeArrow = 'left';
      }

      if (isLeftArrowVisible && isRightArrowVisible) {
        activeArrow = 'all';
      }

      setActiveArrow(activeArrow);
    };

    const observerOptions = {
      root: carouselRef.current,
      threshold: 1.0,
      rootMargin: '1px',
    };

    const firstStyleVariantObserver = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && isLeftArrowVisible) {
        isLeftArrowVisible = false;
        handleArrowVisibility();
      } else if (!entries[0].isIntersecting && !isLeftArrowVisible) {
        isLeftArrowVisible = true;
        handleArrowVisibility();
      }
    }, observerOptions);

    const lastStyleVariantObserver = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && isRightArrowVisible) {
        isRightArrowVisible = false;
        handleArrowVisibility();
      } else if (!entries[0].isIntersecting && !isRightArrowVisible) {
        isRightArrowVisible = true;
        handleArrowVisibility();
      }
    }, observerOptions);

    firstStyleVariantObserver.observe(firstStyleVariant);
    lastStyleVariantObserver.observe(lastStyleVariant);
    // eslint-disable-next-line
    return () => {
      firstStyleVariantObserver?.unobserve(firstStyleVariant);
      lastStyleVariantObserver?.unobserve(lastStyleVariant);
    };
  }, [styleVariants?.length]);

  const animate = useCallback(
    (direction: AnimationDirection, isInitialScroll = false) => {
      if (carouselRef.current && styleVariants?.length) {
        const styleVariantWidth = carouselRef.current.scrollWidth / styleVariants.length;
        // For initial scroll the swatch of selected product should be on the 3rd
        // visible slot and 2 and a half visible swatches are visible to the left of it.
        const initialScrollRange = (productStyleVariantIndex - 2.5) * styleVariantWidth;
        const arrowScrollRange = 100;
        const leftScrollRange = isInitialScroll
          ? initialScrollRange - arrowScrollRange
          : carouselRef.current.scrollLeft;
        const availableLeftScrollRange = leftScrollRange - arrowScrollRange;
        const availableRightScrollRange = leftScrollRange + arrowScrollRange;

        if (direction === 'right') {
          carouselRef.current.scrollTo(
            Math.min(availableRightScrollRange, carouselRef.current.scrollWidth),
            0
          );
        } else {
          carouselRef.current.scrollTo(Math.max(availableLeftScrollRange, 0), 0);
        }
      }
    },
    [productStyleVariantIndex, styleVariants?.length]
  );

  const convertImageUrl = (url: string) => url.replace('{{dimensions}}', 'h_136');

  useEffect(() => {
    if ((styleVariants?.length ?? 0) < 6 || productStyleVariantIndex < 3) {
      return;
    }

    animate('right', true);
  }, [animate, productStyleVariantIndex, styleVariants?.length]);

  const colorDescription = useMemo(
    () => styleVariants?.[styleVariantsSelectedIndex]?.color || color?.description,
    [styleVariants, styleVariantsSelectedIndex, color?.description]
  );

  return (
    <S.ColorSelection data-cs-capture="">
      <S.Title data-testid="color-title">{availableColoursV2 || t('availableColours')}</S.Title>
      <S.Anchor
        href={`/${locale}/shop/all?q=::exactColorDescription:${color?.description?.toLowerCase()}`}
      >
        <S.LinkLabel data-testid="color-label">{colourV2 || t('colour')}</S.LinkLabel>
        <S.ColorName data-testid="color-name">{colorDescription}</S.ColorName>
      </S.Anchor>
      {(styleVariants?.length || 0) > 1 && (
        <S.Carousel>
          <S.ArrowLeft
            data-testid="color-arrow-left"
            onClick={() => animate('left')}
            $activeArrow={activeArrow}
          />
          <S.ArrowRight
            data-testid="color-arrow-right"
            onClick={() => animate('right')}
            $activeArrow={activeArrow}
          />
          <S.CarouselContainer ref={carouselRef} $touchable={touchable}>
            <S.CarouselInner data-testid="style-carousel" $itemCount={styleVariants?.length || 0}>
              {styleVariants?.map((item, index) => (
                <S.CarouselInnerItem
                  key={index}
                  data-testid="style-carousel-item"
                  $isSelected={styleVariantsSelectedIndex === index}
                  onMouseEnter={() => {
                    if (hoverOnCarousel === false && selectColorCarouselIndex && !touchable) {
                      selectColorCarouselIndex(index);
                    }
                  }}
                  onMouseLeave={() => {
                    if (selectColorCarouselIndex) {
                      selectColorCarouselIndex(productStyleVariantIndex);
                    }

                    setHoverOnCarousel(false);
                  }}
                >
                  <Link href={item?.productUrl ?? ''} passHref legacyBehavior>
                    <S.CarouselAnchor
                      $isSelected={styleVariantsSelectedIndex === index}
                      aria-label={`${item?.name} ${item?.color}`}
                      title={`${item?.name} ${item?.color}`}
                    >
                      <Image
                        src={convertImageUrl(item?.images?.thumbnail ?? '')}
                        alt={item?.images?.thumbnailAltText ?? ''}
                        blurDataURL={imageBlurData}
                        placeholder="blur"
                        layout="fill"
                        objectFit="cover"
                        sizes="25vw"
                        loading="lazy"
                        priority={false}
                        lazyBoundary="100px"
                      />
                    </S.CarouselAnchor>
                  </Link>
                </S.CarouselInnerItem>
              ))}
            </S.CarouselInner>
          </S.CarouselContainer>
        </S.Carousel>
      )}
    </S.ColorSelection>
  );
};
