import type { ReactElement } from 'react';
import { Svg } from './styles';

export const HangerIcon = (): ReactElement => (
  <Svg version="1.1" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="100%" height="100%" rx="100" fill="#212529" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 5.905c0 .001 0 .001 0 0 0-.005.005-.031.025-.074a.588.588 0 0 1 .108-.158.533.533 0 0 1 .394-.173c.128 0 .325.108.425.404.092.273.07.653-.227 1.02-.359.445-.546.826-.633 1.192-.044.184-.06.359-.065.515-.08.015-.159.035-.237.058l-4.521 1.34a1.772 1.772 0 0 0 .503 3.471h9.456a1.772 1.772 0 0 0 .503-3.472l-4.52-1.34a2.498 2.498 0 0 0-.183-.046c.003-.1.013-.193.037-.295.045-.19.151-.439.438-.795.511-.633.598-1.371.397-1.968-.194-.573-.694-1.084-1.373-1.084-.48 0-.863.215-1.117.482-.24.252-.41.596-.41.923v.5h1v-.5Zm.074 3.743a1.5 1.5 0 0 1 .852 0l4.521 1.34a.772.772 0 0 1-.22 1.512H5.273a.772.772 0 0 1-.22-1.513l4.522-1.34Z"
      fill="#fff"
    />
  </Svg>
);

export default HangerIcon;
