import type { ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { useExperienceContext } from '../../../../utilities/context/dynamic/ExperienceOptionsProvider';
import { useSizeGuidesContext } from '../../../../utilities/context/dynamic/SizeGuidesContext';
import { useSizeContext } from '../../../../utilities/context/dynamic/SizeContext';
import { SizeType } from '../../../../utilities/graphql/codegen';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { usePDPDynamicContext } from '../../../../utilities/context/dynamic/PDPDynamicContext';
import type { SizeMeasurementsProps } from '../../sizeGuide/SizeMeasurements';
import type { SizeGuideProps } from '../../sizeGuide/SizeGuide';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useDataLayerContext } from '../../../../utilities/context/static/DataLayerContext';
import SizeGuideIcon from '../../../shared/core/icons/SizeGuideIcon';
import { Dialog } from '../../../shared/core/dialog/Dialog';
import { useHashChanged } from '../../../../utilities/hooks';
import { getLocation } from '../../../../utilities/helpers';
import { colors, spacing } from '../../../../components/shared/core/styles';
import HangerIcon from '../../../shared/core/icons/HangerIcon';

const SizeMeasurements = dynamic<SizeGuideProps & SizeMeasurementsProps>(
  () => import('../../sizeGuide/SizeMeasurements').then(mod => mod.SizeMeasurements),
  { ssr: false }
);

const SizeGuide = dynamic<SizeGuideProps>(
  () => import('../../sizeGuide/SizeGuide').then(mod => mod.SizeGuide),
  { ssr: false }
);

const S = {
  Wrapper: styled.div`
    display: flex;
    padding: ${spacing.XXS} ${spacing.XXS} ${spacing.XS};
    border: 1px solid ${colors.BLUE_CHARCOAL};
    background: #fff;
    margin: ${spacing.XS} 0 ${spacing.XXXS};
  `,

  Title: styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 1.5;
  `,

  Content: styled.div`
    font-weight: normal;

    &::first-letter {
      text-transform: uppercase;
    }
  `,

  SizeGuideLink: styled.a`
    text-decoration: none;
    display: inline-block;
  `,

  SizeGuideLinkLabel: styled.span`
    height: 20px;
    text-transform: capitalize;
    text-decoration: underline;
    margin-left: 6px;
    font-weight: bold;
    color: ${colors.BLUE_CHARCOAL};

    &:hover,
    &:focus {
      color: ${colors.NERO_GREY};
    }
  `,

  SizeGuideIconWrapper: styled.span`
    display: inline-block;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    margin-top: -2px;
    vertical-align: top;
    border: 1px solid black;
    border-radius: 50%;
    padding: 3px 3px 3px 5px;
  `,

  HangerIconWrapper: styled.span`
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: -2px 6px 0 0;
  `,

  LinksWrapper: styled.div`
    display: flex;
    margin-top: 12px;
  `,

  FitAnalyticsLink: styled.button`
    display: flex;
    color: black;
    text-decoration: underline;
    margin-right: 16px;
  `,

  SVG: styled.svg`
    margin-right: 6px;
  `,
};

interface MeasurementsComponentProps {
  title: string;
  sizeGuideLabelV2: string;
  sizeGuideLink: string;
}

export const MeasurementsComponent = ({
  title,
  ...props
}: MeasurementsComponentProps): ReactElement => {
  const { t } = useTranslation('pdp', { keyPrefix: 'productSizeGuide' });
  const { product } = usePDPContext();

  const {
    configuration: { enableSizeGuideProductMeasurements },
  } = useStaticContext();
  const [content, setContent] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [showFitAnalyticsLink, setShowFitAnalyticsLink] = useState(false);
  const [showSizeMeasurements, setShowSizeMeasurements] = useState(
    enableSizeGuideProductMeasurements && product.shouldRequestSizeMeasurements
  );
  const { sizeGuidesData, unit, selectionIndexArray, sizeSelectionNavIndex, tableJsonData } =
    useSizeGuidesContext();
  const { gridValues } = useSizeContext();
  const { pushToDataLayer } = useDataLayerContext();
  const { pushToContentSquare } = useAppContext();
  const { dynamicProductData } = usePDPDynamicContext();
  const { experienceOptions } = useExperienceContext();
  const [fitAnalyticsLink, setFitAnalyticsLink] = useState<HTMLAnchorElement | null>(null);
  const { sizeGuideLabelV2, sizeGuideLink } = props;

  const handleNoSizeMeasurementsData = () => {
    setTimeout(() => {
      setShowSizeMeasurements(false);
    }, 0);
  };

  const clickSizeGuideEventData = {
    event: 'abtest.event',
    'abtest.action': experienceOptions?.tk20?.experienceId,
    'abtest.label': `click_sizeguide`,
  };

  const clickFitAnalyticsEventData = {
    event: 'abtest.event',
    'abtest.action': experienceOptions?.tk20?.experienceId,
    'abtest.label': `click_findmysize`,
  };

  const { hashMatched, setHashMatched } = useHashChanged(
    ['#size-guide', '#measurement-guide'],
    clickSizeGuideEventData
  );

  const { labelTranslations = {} } = sizeGuidesData ?? {};
  const sizeInformation = dynamicProductData.sizeInformation ?? product.sizeInformation;

  const getTranslatedLabel = useCallback(
    (className: string) => {
      switch (className) {
        case 'arm':
          return labelTranslations?.arm;
        case 'chest':
          return labelTranslations?.chest;
        case 'foot':
          return labelTranslations?.foot;
        case 'hip':
          return labelTranslations?.hip;
        case 'inseam':
          return labelTranslations?.inseam;
        case 'length':
          return labelTranslations?.length;
        case 'size':
          return labelTranslations?.size;
        case 'waist':
          return labelTranslations?.waist;
        default:
          return '';
      }
    },
    [
      labelTranslations?.arm,
      labelTranslations?.chest,
      labelTranslations?.foot,
      labelTranslations?.hip,
      labelTranslations?.inseam,
      labelTranslations?.length,
      labelTranslations?.size,
      labelTranslations?.waist,
    ]
  );

  useEffect(() => {
    setFitAnalyticsLink(document.querySelector('.fitanalytics a') as HTMLAnchorElement);
  }, []);

  useEffect(() => {
    if (!tableJsonData.length) {
      return;
    }

    const dataArray = tableJsonData[sizeSelectionNavIndex]?.map((item, index) =>
      item.table.tbody.tr.filter((tr, i) => {
        const selectedSize = index === 0 ? gridValues.gridValue1 : gridValues.gridValue2;

        return i === 0 || tr.td[0]['#text'] === selectedSize;
      })
    );

    let resultString = '';

    dataArray.forEach(arr => {
      const [keys, values] = arr.map(item => item.td.slice(1));

      if (!values) {
        return;
      }

      const contentText = keys
        .map(
          (key, index) =>
            `${(getTranslatedLabel(key['#text']) ?? key['#text']).toLowerCase()}: ${
              values[index]['#text']
            }${unit}`
        )
        .join(', ');

      resultString = resultString ? `${resultString}, ${contentText}` : contentText;
    });

    setContent(resultString);
  }, [
    selectionIndexArray,
    sizeSelectionNavIndex,
    tableJsonData,
    selectedSize,
    gridValues.gridValue1,
    gridValues.gridValue2,
    getTranslatedLabel,
    labelTranslations,
    unit,
  ]);

  useEffect(() => {
    if (!gridValues.gridValue1) {
      setSelectedSize('');
    } else if (sizeInformation?.sizeType !== SizeType.NumericDouble) {
      setSelectedSize(`${gridValues.gridValue1}`);
    } else if (gridValues.gridValue2) {
      setSelectedSize(`${gridValues.gridValue1}/${gridValues.gridValue2}`);
    }
  }, [gridValues?.gridValue1, gridValues?.gridValue2, sizeInformation?.sizeType]);

  useEffect(() => {
    let observer: MutationObserver;

    if (experienceOptions?.tk20?.translations?.fitAnalyticsLabel) {
      if (fitAnalyticsLink) {
        setShowFitAnalyticsLink(!(fitAnalyticsLink.style.display === 'none'));
      } else {
        const waitForFitAnalytics = (selector: string, callback: () => void) => {
          observer = new MutationObserver((mutationsList, observer) => {
            mutationsList.forEach(mutation => {
              if (mutation.type === 'childList') {
                const element = document.querySelector(selector);

                if (!element) {
                  return;
                }

                setFitAnalyticsLink(element as HTMLAnchorElement);

                const checkDisplay = setInterval(() => {
                  if (getComputedStyle(element).display !== 'none') {
                    callback();
                    clearInterval(checkDisplay);
                    observer.disconnect();
                  }
                }, 500);
              }
            });
          });

          observer.observe(document.body, { childList: true, subtree: true });
        };

        waitForFitAnalytics('.fitanalytics a', () => {
          setShowFitAnalyticsLink(true);
        });
      }
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [experienceOptions?.tk20?.translations?.fitAnalyticsLabel, fitAnalyticsLink]);

  const handleOpenSizeGuideModal = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setHashMatched(true);

    pushToDataLayer(clickSizeGuideEventData);

    pushToContentSquare('trackPageview', `${getLocation()}?cs-popin-sizeguide`);
  };

  const handleCloseSizeGuideModal = () => {
    setHashMatched(false);
    pushToContentSquare('trackPageview', getLocation());
  };

  return selectedSize && content ? (
    <>
      <S.Wrapper>
        <S.Title>
          {title?.replace('{selected_size}', selectedSize)}
          <S.Content>{content}</S.Content>
          <S.LinksWrapper>
            {showFitAnalyticsLink && (
              <S.FitAnalyticsLink
                onClick={() => {
                  pushToDataLayer(clickFitAnalyticsEventData);
                  fitAnalyticsLink?.click();
                }}
              >
                <S.HangerIconWrapper data-testid="find-my-size-icon">
                  <HangerIcon />
                </S.HangerIconWrapper>
                {experienceOptions?.tk20?.translations?.fitAnalyticsLabel}
              </S.FitAnalyticsLink>
            )}
            <S.SizeGuideLink
              href={sizeGuideLink}
              onClick={handleOpenSizeGuideModal}
              data-testid="tk20-size-guide-link"
            >
              <S.SizeGuideIconWrapper data-testid="size-guide-icon">
                <SizeGuideIcon />
              </S.SizeGuideIconWrapper>
              <S.SizeGuideLinkLabel data-cs-capture="">
                {sizeGuideLabelV2 || t('sizeGuideLabel')}
              </S.SizeGuideLinkLabel>
            </S.SizeGuideLink>
          </S.LinksWrapper>
        </S.Title>
      </S.Wrapper>
      {hashMatched && (
        <Dialog
          ariaLabel="size guide"
          onClose={handleCloseSizeGuideModal}
          position={showSizeMeasurements ? 'center' : 'top'}
          overlayColor={colors.DIALOG_DARK_GREY}
        >
          {showSizeMeasurements ? (
            <SizeMeasurements
              labelProps={props}
              handleNoSizeMeasurementsData={handleNoSizeMeasurementsData}
            />
          ) : (
            <SizeGuide labelProps={props} />
          )}
        </Dialog>
      )}
    </>
  ) : (
    <></>
  );
};
