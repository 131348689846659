export const fiberSvgDefs = `
  <div style="position:absolute;left:-100%;top:-100%">
    <svg viewbox="0,0,32,32" xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="2.0">
      <defs>
        <mask id="fiberRanking-circleMask" x="0" y="0" width="128" height="128" >
          <rect x="0" y="0" width="128" height="128" style="stroke:none; fill: #000000"/>
          <circle cx="66" cy="66" r="49" fill="none" stroke="#ffffff" stroke-miterlimit="0" stroke-width="17" />
        </mask>
        <pattern id="fiberRanking-pattern-BCI" patternTransform="scale(0.5)" patternUnits="userSpaceOnUse" width="4" height="4">
          <path d="M3,-1 l2,2 M0,0 l4,4 M-1,3 l2,2"
          style="stroke:#999; stroke-width:1" />
        </pattern>
      </defs>
    <svg>
  </div>
`;
