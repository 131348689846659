import type { ReactElement, MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { media, colors } from '../../../shared/core/styles';

export interface ArrowProps {
  testId?: string;
  direction: number;
  ariaLabel: string | undefined;
  galleryZoomIn: boolean;
  clickHandler: (event: MouseEvent) => void;
}

const styles = {
  Arrow: css<{ $galleryZoomIn: boolean; $direction: number }>`
    cursor: pointer;
    display: block;
    position: ${({ $galleryZoomIn }) => ($galleryZoomIn ? 'fixed' : 'absolute')};
    width: 44px;
    height: 64px;
    top: ${({ $galleryZoomIn }) => ($galleryZoomIn ? '50vh' : '50%')};
    margin-top: -32px;
    text-align: center;
    z-index: 13;
    background: transparent var(--tiny-gif) 0 0 repeat scroll;

    &::before {
      display: inline-block;
      font-style: normal;
      font-weight: 400;
      line-height: 64px;
      text-decoration: inherit;
      width: 1em;
      text-align: center;
      vertical-align: middle;
      font-variant: normal;
      text-transform: none;
      font-size: 40px;
      color: ${colors.ACCESSIBILITY_GREY};
    }

    @media ${media.greaterThan('sm')} {
      display: none;
    }
  `,
};

const S = {
  Arrow: styled.button<{ $galleryZoomIn: boolean; $direction: number }>`
    ${styles.Arrow};
    border: 0;
    ${({ $direction }) => ($direction === -1 ? 'left: 0' : 'right: 0')};

    &::before {
      content: ${({ $direction }) => ($direction === -1 ? "'\\2039'" : "'\\203a'")};
    }
  `,
};

export const Arrow = ({
  testId,
  direction,
  ariaLabel,
  galleryZoomIn,
  clickHandler,
}: ArrowProps): ReactElement => (
  <S.Arrow
    data-testid={testId}
    aria-label={ariaLabel}
    $galleryZoomIn={galleryZoomIn || false}
    $direction={direction}
    onClick={clickHandler}
  />
);
